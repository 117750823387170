<!-- eslint-disable vue/html-self-closing -->
<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ms-0">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div
      class="content-wrapper container-xxl p-0"
    >
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Host Institution - {{ data.convo }}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div class="content-body">
        <div class="row">
          <div class="col-6">
            <table class="table">
              <tbody>
                <tr>
                  <td style=" font-weight: bold;">
                    Applicant
                  </td>
                  <td> {{ data.applicant }} </td>
                </tr>

                <tr>
                  <td style=" font-weight: bold;">
                    Host institution
                  </td>
                  <td>{{ data.host }} </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="data.end_date"
            class="col-12"
          >
            <p>Please, download, complete and sign <a :href="data.eoi.url">this form</a> (MS Word)</p>
            <p>This form has to be filled, signed and uploaded electronically to the ICREA system before {{ data.end_date }} (Central European Time).</p>
            <p>Once uploaded it, you cannot modify it but you can replace it for a new one.</p>
            <p>
              <em>Please note that only electronic EOIs are accepted in this call. Paper EOIs are no valid for submission.</em>
            </p>
          </div>
        </div>

        <section
          v-if="data.end_date"
          id="senior__call__form"
          class="senior__call__form mt-2"
        >
          <div class="row">
            <div class="col-3">
              <div
                class="mb-1 form-group required"
              >
                <h3>
                  Expression of interest
                </h3>
              </div>
            </div>
            <div class="col-12">
              <vue-dropzone
                id="dropzone"
                ref="myVueDropzone"
                :options="dropzoneOptions"
                @vdropzone-success="submitAnswer"
              ></vue-dropzone>
            </div>
            <div
              v-if="sent"
              class="col-11 alert m-auto alert-success p-2 mt-2"
            >
              Letter uploaded successfully. Thanks for your cooperation!
            </div>
          </div>
        </section>
        <h4
          v-else
          class="mt-3 alert alert-danger p-2"
        >
          The date for uploading documents has ended
        </h4>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {},
  data() {
    return {
      sent: false,
      data: {
        end_date: true,
      },
      dropzoneOptions: {
        url: `${Vue.prototype.$groupUrl}/parameter/save-institution-response`,
        maxFiles: 1,
        params: {
          payload: this.$route.params.payload,
        },
        thumbnailWidth: 150,
        acceptedFiles: 'application/pdf',
        maxFilesize: 1,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      },
    }
  },
  async mounted() {
    const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/parameter/parse-institution-payload`, { payload: this.$route.params.payload })
    this.data = resp.data
  },
  methods: {
    submitAnswer() {
      this.sent = true
    },
  },
}
</script>
